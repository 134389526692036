import { Link, useLocation } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import {
  getProjectsAndStudentsPerCourse,
  navbarActiveCourse,
  navbarActiveProject,
  navbarActiveStudent,
  toggleHamburgerMenu,
  updateComponentError,
} from '../app/navbarSlice';
import {
  allProjectsSelectedGraphKey,
} from '../app/allProjectsSlice';
import {
  trendSelectedGraphKey
} from '../app/trendSlice';
import { useAppDispatch, useAppSelector } from '../app/hooks';

import Theme from '../sharedStyles/Theme';
import styled from 'styled-components';

export type navLinkProps = {
  label: string;
  path: string;
  redirect?: string;
};

function getStorageValue(key: string, defaultValue: string) {
  // getting stored value
  if (typeof window !== 'undefined') {
    const saved = localStorage.getItem(key);
    const initial = saved !== null ? JSON.parse(saved) : defaultValue;
    return initial;
  }
}

const { REACT_APP_FRONTEND_URI, REACT_APP_BACKEND_URI } = process.env;

const NavBar = () => {
  const location = useLocation();
  const showHamburger = useAppSelector(
    (state) => state.navbar.showHamburgerMenu,
  );
  const courseValue = useAppSelector(navbarActiveCourse).value;
  console.log(`Navbar course value ${courseValue}`);
  const courseLabel = useAppSelector(navbarActiveCourse).label;
  const projectValue = useAppSelector(navbarActiveProject).value;
  const studentValue = useAppSelector(navbarActiveStudent).value;
  const prjTrend_ActivityPerStudentPerDayMetric = useAppSelector(trendSelectedGraphKey)[
    'selectedBoxplot'
  ];
  const prjTrend_ActiveStudentsPerDayMetric = useAppSelector(trendSelectedGraphKey)[
    'selectedActivity'
  ];
  const studentAll_ActivityPerStudentPerProjectMetric = useAppSelector(allProjectsSelectedGraphKey)[
      'selectedBoxplot'
  ];



  const cookieValue = getStorageValue('jwt', 'baljit');
  const headers = new Headers();
  headers.append('x-access-token', cookieValue);
  const [showQuiz, setShowQuiz] = useState(false);

  useEffect(() => {
    if (courseValue) {
      // Fetch quiz status
      const fetchQuizStatus = async () => {
        try {
          const response = await fetch(
            `${REACT_APP_BACKEND_URI}/api/${courseValue}/config/cheatchecking/`,
            {
              method: 'GET',
              headers: headers,
            },
          ); // Replace with your actual API endpoint
          if (response.ok) {
            const data = await response.json();
            if (data[0].value === "true") {
               setShowQuiz(true);
            } else {
               setShowQuiz(false);
            }
          } else {
            console.error('Error fetching quiz status:', response.statusText);
            setShowQuiz(false);
          }
        } catch (error) {
          console.error('Error fetching quiz status:', error);
          setShowQuiz(false);
        }
      };

      fetchQuizStatus();
    }
  }, [courseValue]);

  useEffect(() => {
    // storing input name
    if (
      window.location.pathname.split('/').includes('auth') &&
      window.location.pathname.split('/').length === 4
    ) {
      const courseSlugFromURL = window.location.pathname.split('/')[2];
      const cookieValue = window.location.pathname.split('/')[3];
      localStorage.setItem('jwt', JSON.stringify(cookieValue));
      window.location.href = `${REACT_APP_FRONTEND_URI}/${courseSlugFromURL}/`;
    } else if (
      window.location.pathname.split('/').includes('auth') &&
      window.location.pathname.split('/').length === 5
    ) {
      const courseSlugFromURL = window.location.pathname.split('/')[2];
      const projectSlugFromURL = window.location.pathname.split('/')[3];
      const cookieValue = window.location.pathname.split('/')[4];
      localStorage.setItem('jwt', JSON.stringify(cookieValue));
      window.location.href = `${REACT_APP_FRONTEND_URI}/${courseSlugFromURL}/${projectSlugFromURL}`;
    }

    console.log(`Navbar useEffect() ${window.location.href}`);
  }, []);

  

  useEffect(() => {
    if (courseValue) {
      const logNavigation = async (fullpath, courseValue, projectValue, studentValue, 
        prjTrend_ActivityPerStudentPerDayMetric, 
        prjTrend_ActiveStudentsPerDayMetric, studentAll_ActivityPerStudentPerProjectMetric  ) => {
        try {
          let path = fullpath;
          if (path.startsWith("/auth")) {
            path = "/auth"; // Hide auth info from logs
          }
          if (path.startsWith("/api/auth")) {
            path = "/api/auth"; // Hide auth info from logs
          }
          await fetch(`${REACT_APP_BACKEND_URI}/api/${courseValue}/dashboard`, {
            method: "POST",
            body: JSON.stringify({
              path: path,
              module: projectValue,
              student: studentValue,
              "activity_per_student_per_day_metric": prjTrend_ActivityPerStudentPerDayMetric,
              "students_per_day_metric": prjTrend_ActiveStudentsPerDayMetric,
              "activity_per_student_per_project_metric": studentAll_ActivityPerStudentPerProjectMetric
            }),
            headers: { ...headers, "Content-Type": "application/json", "x-access-token": getStorageValue('jwt', 'baljit') },
          });
          console.log(`Navigation logged: ${path}`);
        } catch (error) {
          console.error("Failed to log navigation:", error);
        }
      };

      logNavigation(location.pathname, courseValue, projectValue, studentValue, 
        prjTrend_ActivityPerStudentPerDayMetric.value, prjTrend_ActiveStudentsPerDayMetric.value, 
        studentAll_ActivityPerStudentPerProjectMetric.value);
    }
  }, [location, projectValue, studentValue, courseValue, prjTrend_ActivityPerStudentPerDayMetric, 
    prjTrend_ActiveStudentsPerDayMetric, studentAll_ActivityPerStudentPerProjectMetric]);

  const courseSlugFromURL = window.location.pathname.split('/')[1];
  const projectSlugFromURL = window.location.pathname.split('/')[2];

  const navLinks: navLinkProps[] = [
    {
      label: 'Projects',
      path: '/projects',
      redirect: `/${courseValue}/${projectValue}/projects/snapshot`,
    },
    {
      label: 'Students',
      path: '/students',
      redirect: `/${courseValue}/${projectValue}/students/selected-project`,
    },
    ...(showQuiz
      ? [
          {
            label: 'Quiz Cheat Checking',
            path: '/cheatchecking',
            redirect: `/${courseValue}/cheatchecking/quizzes`,
          },
          {
            label: 'Classic',
            path: '/classic',
            redirect: `/${courseValue}/classic/classic-project`,
          },
        ]
      : []),
  ];

  const reduxDispatch = useAppDispatch();

  useEffect(() => {
    if (courseSlugFromURL === '404') {
      reduxDispatch(updateComponentError('404'));
    } else {
      reduxDispatch(
        getProjectsAndStudentsPerCourse(courseSlugFromURL, projectSlugFromURL),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseSlugFromURL, reduxDispatch]);

  const myRef = useRef<HTMLDivElement>(null);
  const myRef2 = useRef<HTMLButtonElement>(null);

  const handleClick = (e: { target: any }) => {
    const node = myRef.current;
    const node2 = myRef2.current;

    if (node && node.contains(e.target)) {
      return;
    }

    if (node2 && node2.contains(e.target)) {
      return;
    }

    if (showHamburger === false) {
      return;
    }
    reduxDispatch(toggleHamburgerMenu());
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  const changeCourseWidth = () => {
    const charCount =
      courseLabel === 'sail-ppp-dashboard-demo' ? 28 : courseLabel.length;
    const width = charCount < 8 ? 8 : 1.5 * Math.pow(charCount, 0.69);

    return `${width}rem`;
  };

  return (
    <Theme>
      <StyledNavBar>
        <a href={`/${courseValue}`}>
          <StyledLogo
            src="/images/sail-dashboard-logo.svg"
            alt="Sail Dashboard Logo"
          />
        </a>
        {courseValue ? (
          <StyledNavItems>
            {navLinks.map((link: navLinkProps) => (
              <StyledNavItem
                key={link.path}
                active={location.pathname.includes(link.path)}
              >
                <Link
                  to={{
                    pathname: link.redirect ? link.redirect : link.path,
                  }}
                >
                  {link.label}
                </Link>
              </StyledNavItem>
            ))}
          </StyledNavItems>
        ) : (
          <></>
        )}
        <Right>
          <StyledCourseButton width={changeCourseWidth()}>
            {courseLabel === 'sail-ppp-dashboard-demo'
              ? 'Sail Programming with Python'
              : courseLabel}
          </StyledCourseButton>
          <HamburgerMenuIcon
            onClick={() => reduxDispatch(toggleHamburgerMenu())}
            ref={myRef2}
          >
            <img
              style={{ width: '1.5rem' }}
              src={
                showHamburger
                  ? '/images/close-hamburger-icon.svg'
                  : '/images/hamburger-icon.svg'
              }
              alt="Navigation Menu Icon"
            />
          </HamburgerMenuIcon>
        </Right>
      </StyledNavBar>
      <HamburgerMenu showHamburger={showHamburger} ref={myRef}>
        {navLinks.map((link: navLinkProps) => (
          <HamburgerMenuNavButton
            key={link.path}
            active={location.pathname.includes(link.path)}
          >
            <Link
              to={{
                pathname: link.redirect ? link.redirect : link.path,
              }}
              onClick={() => reduxDispatch(toggleHamburgerMenu())}
            >
              {link.label}
            </Link>
          </HamburgerMenuNavButton>
        ))}
      </HamburgerMenu>
    </Theme>
  );
};

export default NavBar;

const HamburgerMenu = styled.div<{ showHamburger: boolean }>`
  display: none;
  @media (max-width: 1000px) {
    display: ${(props) => (props.showHamburger ? 'block' : 'none')};
    list-style: none;
    align-items: center;
    text-align: center;
    width: 30%;
    min-width: 20rem;
    align-self: flex-end;
    position: fixed;
    top: 3.5rem;
    right: 1rem;
    padding: 1rem 0;
    background-color: ${(props) => props.theme.colors.white};
    opacity: 0.95;
    z-index: 99;
    font-size: 1.25rem;
    height: 100%;
  }
`;
export const Right = styled.div`
  display: flex;
`;
export const HamburgerMenuIcon = styled.button<{ onClick: any }>`
  display: none;
  @media (max-width: ${(props) => props.theme.responsive.md}) {
    display: block;
    border: none;
    background-color: transparent;
    margin-left: 1rem;
  }
`;

export const StyledNavItems = styled.ul`
  width: 100%;
  color: ${(props) => props.theme.colors.secondaryDark};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 1.5rem;
  list-style: none;
  @media (max-width: ${(props) => props.theme.responsive.md}) {
    display: none;
  }
`;

export const StyledNavItem = styled.li<{ active: boolean }>`
  background-color: transparent;
  border: none;
  box-sizing: border-box;
  margin: 0 2rem;
  padding-bottom: 1rem;
  color: ${(props) => (props.active ? `${props.theme.colors.brandDark}` : '')};
  border-bottom: ${(props) =>
    props.active
      ? `.25rem solid ${props.theme.colors.brandDark}`
      : '.25rem solid transparent'};
  &:hover {
    border-bottom: ${(props) => `.25rem solid ${props.theme.colors.brandDark}`};
  }
`;

export const HamburgerMenuNavButton = styled(StyledNavItem)`
  @media (max-width: ${(props) => props.theme.responsive.md}) {
    margin: 0;
    width: 100%;
    padding: 2rem 1rem;

    border-bottom: 0.1rem solid rgba(159, 160, 162, 0.3);
    &:first-child {
      border-top: 0.1rem solid rgba(159, 160, 162, 0.3);
    }

    &:hover {
      border-bottom: none;
      background-color: ${(props) => props.theme.colors.textBackground};
    }
  }
`;

const StyledLogo = styled.img`
  display: flex;
  width: 13rem;
  height: auto;
  @media (max-width: 500px) {
    width: 10rem;
  }
`;

export const StyledNavBar = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  height: 4rem;
  display: flex;
  justify-content: space-between;
  padding: 0 2%;
  position: sticky;
  z-index: 99;
  top: 0;
  align-items: center;
  @media (max-width: ${(props) => props.theme.responsive.md}) {
    padding: 0.5rem 2%;
    height: auto;
  }
`;

export const StyledCourseButton = styled.div<{ width: string }>`
  background: ${(props) => props.theme.colors.brandDark};
  border-radius: 20px;
  color: ${(props) => props.theme.colors.white};
  width: ${(props) => props.width};
  display: flex;
  padding: 0.5rem;
  justify-content: center;
`;
